import React from 'react';

function Experience() {

  const navigateToProjects = () => {
    window.location.href = './#projects';
  };

  return (
    <section id="experience">
      <p className="section__text__p1">Explore My</p>
      <h1 className="title">Experience</h1>
      <div className="experience-details-container">
        <div className="about-containers">
            <div className="details-container">
                <h2 className="experience-sub-title">Frontend Development</h2>
                <div className="article-container">
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>HTML</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>CSS</h3>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>JavaScript</h3>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>React</h3>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>Material UI</h3>
                            <p>Intermediate</p>
                        </div>
                    </article>
                </div>
            </div>
            <div className="details-container">
                <h2 className="experience-sub-title">Backend Development</h2>
                <div className="article-container">
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>Java</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>Python</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>C/C++</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>MySQL</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>MongoDB</h3>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img
                            src="./assets/checkmark.png"
                            alt="Experience Icon"
                            className="icon"
                        />
                        <div>
                            <h3>REST APIs</h3>
                            <p>Experienced</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
        <div className="centered-container">
            <div className="details-container">
                <h2 className="experience-sub-title">Familiar Technologies</h2>
                <div className="article-container">
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Git</h5>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Jira</h5>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Flask</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Spring Boot</h5>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Bootstrap-CSS</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>BeautifulSoup</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Requests</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Selenium</h5>
                            <p>Experienced</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Junit5</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>PyTorch</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Scikit-learn</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Pandas</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>Splunk</h5>
                            <p>Intermediate</p>
                        </div>
                    </article>
                    <article>
                        <img src="./assets/checkmark.png" alt="Experience Icon" className="icon" />
                        <div>
                            <h5>AWS (DynamoDB, EC2, Lambda, S3)</h5>
                            <p>Experienced</p>
                        </div>
                    </article>
                </div>
            </div>
          </div>
        </div>
      <img 
      src="./assets/arrow.png"
      alt="Arrow icon"
      className="icon experience-arrow"
      onClick={navigateToProjects}
      />
    </section>
  );
}

export default Experience;
