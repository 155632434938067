import React from 'react';

function Footer() {
  return (
    <footer>
      <nav>
          <div class="nav-links-container">
              <ul class="nav-links">
                  <li><a href="#about">About</a></li>
                  <li><a href="#experience">Experience</a></li>
                  <li><a href="#projects">Projects</a></li>
                  <li><a href="#contact">Contact</a></li>
              </ul>
          </div>
      </nav>
      <p>Copyright &#169; 2024 Landyn Moreno. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
